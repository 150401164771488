import React, { Component } from "react";

class StudiesList extends Component {
  render() {
    return (
      <div>
        <div className="container container__body">
          I am a list of all the studies
        </div>
      </div>
    );
  }
}
export default StudiesList;
